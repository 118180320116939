import React from "react";
import {
    Section
} from "/node_modules/gatsby-theme-portfolio-minimal/src/components/Section/index.tsx";
import { parse } from 'csv-parse/browser/esm/sync';

const sectionStyles = {
    // backgroundColor: "#D4D4D4",
    // padding: 20
}

const fileButtonStyles = {
    backgroundColor: "#FFFFFF00",
    textColor: "#FFFFFF",
}

class FileDisplayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { fileContent: null, parsedContent: null };
        this.showFile = this.showFile.bind(this);
    }

    showFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            this.setState({
                fileContent: text,
                parsedContent: parse(text, {
                    columns: true,
                    skip_empty_lines: true
                })
            })
        };
        reader.readAsText(e.target.files[0]);
    };

    parseText = (text) => {
        return text;
    }

    render() {

        return (
            <Section style={sectionStyles}>
                <h2>Let Me Read Your (CSV)Files.</h2>
                <input style={fileButtonStyles} type="file" onChange={this.showFile} />
                <table>
                    <thead>
                        <tr>
                            {!(this.state.parsedContent === null) ? Object.keys(this.state.parsedContent[0]).map((key, index) => (
                                <th>{key}</th>
                            )) : void (0)}
                        </tr>
                    </thead>
                    <tbody>
                        {console.log(this.state.parsedContent)}
                        {!(this.state.parsedContent === null) ? this.state.parsedContent.map(ro => (
                            <tr key={ro}>
                                {Object.keys(ro).map((key, index) => (
                                    <td>{ro[key]}</td>
                                ))}
                            </tr>
                        )) : console.log("nothing")}
                    </tbody>
                </table>
            </Section>
        );
    }

}

export default FileDisplayer